.scroll-table{
    overflow: auto;
    white-space: nowrap;
}
.table-header-center th {
    text-align: center;
    min-width: 100px;
    padding: 0px 5px;
}
.table-header-center td{
    padding: 5px 5px 5px 5px;
}
.table-header-center .react-datepicker-wrapper input{
    width: 241px;
}
.table th, .table td {
    padding: 0.25rem;
}

.group-list-config{
    min-width: 429px;
    max-width: 75%;
}

.input-group-prepend-config{
    height: 60px;
}

.input-group-prepend-config-frontlabel .input-group-text{
    width: 257px;
    height: 60px;    
}
    

.input-group-prepend-config-backlabel .input-group-text{
    width: 62;
    height: 60px;
}

.bg-gray{
    background-color: rgb(233, 236, 239);
}
.posManagerment-pos_Block{
    width: 40%;
    min-width: 350px;
    display: inline-flex;
}

.imageReturn{
    max-width: 100%;
    max-height: 100%;
}

.filter-input{
    padding: 0px 0px 0px 0px !important;
}
.filter-input select {
    width: 199px;
}
.filter-input > input {
    width: 100%;
    height: 31px;
    border: 1px solid #ced4da !important;
    border-radius: 0.3rem !important;
    font-family: inherit;
}
.header-select-statistic{
    width: 199px;
}
.smallOption-statistic{
    width: 60px !important;
    padding-left: 0px;
    padding-right: 20px;
}
.smallOption > input{
    width: 139px;
    border: 1px solid #ced4da !important;
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.w-90{
    width: 90% !important;
}
.modal-fullsize{
    max-width: 90% !important;
    max-height: 90% !important;
}
.revenueStatistic_main_formGroup input{
    height: 37px;
}

.table-overflow-x > .react-bootstrap-table table{
    table-layout: auto!important;
}
.overflow-x-auto{
    overflow: auto;
}