.group-accountant{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1050px;
}


.clerk{
    border-top: none !important;
}

.clerk .react-tabs{
    height: 90vh;
}

.clerk .react-tabs li{
    font-weight: 500;
    color: black;
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
}

.clerk .react-tabs .react-tabs__tab-list{
    margin: 0;
    padding: 0;
    display: flex;
}

.clerk .react-tabs .react-tabs__tab{
    border-color: lightgrey;
    max-width: 34%;
    height: 100%;
    padding-left: 1%;
    min-height: 80px;
}
.mobile .react-tabs .react-tabs__tab{
    border-color: lightgrey;
    max-width: 25%;
    height: 100%;
    padding-left: 1%;
    min-height: 80px;
}
.mobile .nav-tabs .nav-item{
    max-width: 20%;
    padding: 5px;
}

.clerk .react-tabs .react-tabs__tab--selected{
    background-color: #ceeee1;
    border-color: lightgrey !important;
}

.clerk .react-tabs .react-tabs__tab-panel{
    border-top: none;
    padding: 5px;
    background-color: #ceeee1;
    max-height: 85vh;
    overflow-y: auto;
}

/* Block */
.clerk .block{
    border: solid 1px lightgrey;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    background-color: white;
}

/* Group content */
.group-accountant .group-content{
    padding-right: 7px;
    padding-left: 7px;
    border: none;
}

.group-accountant .group-content .main-content{
    border: solid 1px lightgrey;
    height: 99vh;
    overflow-y: auto;
    border-radius: 10px;
}

.group-accountant .group-content .main-content .ticket-photo{
    min-height: 200px;
    max-height: 200px;
    min-width: 300px;
    max-width: 300px;
    border: solid 1px lightgrey;
    cursor: zoom-in;
}

/* Ticket */
.group-accountant .group-content .main-content .ticket table tr td:first-child{
    text-align: left;
}

/* modal */
.modal-camera video{
    max-width: 100%;
}

.modal-camera .modal-body{
    min-height: 520px;
}
.loading-modal-camera{
    left: 25%;
}

.cursor-pointer{
    cursor: pointer;
}

.clerk-modal_reject_ticket{
    max-width: 70% !important;
}

.ticket-photo{
    min-height: 200px;
    max-height: 200px;
    min-width: 300px;
    max-width: 300px;
    border: solid 1px lightgrey;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

/* recharge */
.mobile .tab-recharge .search-row{
    width: 100%!important;
}
.clerk .tab-recharge .search-row{
    width: 50%;
}

._word-break-all{
    word-break: break-all;
}
._tittle-blue{
    color: blue;
    font-weight: 600;
}