.group-accountant{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.accountant > div{
    border: solid 1px lightgrey;
    border-radius: 10px;
}

.accountant .group-tabs-handle{
    border-top: none !important;
}

.accountant .group-tabs-handle .react-tabs{
    height: 90vh;
}

.accountant .group-tabs-handle .react-tabs li{
    /* width: 50%; */
    /* text-align: center; */
    font-weight: 500;
    color: black;
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
}

.accountant .group-tabs-handle .react-tabs .react-tabs__tab-list{
    margin: 0;
    padding: 0;
}

.accountant .group-tabs-handle .react-tabs .react-tabs__tab{
    border-color: lightgrey;

}

.accountant .group-tabs-handle .react-tabs .react-tabs__tab--selected{
    background-color: #ceeee1;
    border-color: lightgrey !important;
}

.accountant .group-tabs-handle .react-tabs .react-tabs__tab-panel{
    border-top: none;
    padding: 5px;
    background-color: #ceeee1;
    max-height: 85vh;
    overflow-y: auto;
}

/* Block */
.block{
    border: solid 1px lightgrey;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    background-color: white;
}

/* Group content */
.group-accountant .group-content{
    padding-right: 7px;
    padding-left: 7px;
    border: none;
}

.group-accountant .group-content .main-content{
    border: solid 1px lightgrey;
    height: 99vh;
    overflow-y: auto;
    border-radius: 10px;
}

.group-accountant .group-content .main-content .ticket-photo{
    min-height: 200px;
    max-height: 200px;
    min-width: 300px;
    max-width: 300px;
    border: solid 1px lightgrey;
    cursor: zoom-in;
}

/* Ticket */
.group-accountant .group-content .main-content .ticket table tr td:first-child{
    text-align: left;
}

/* modal */
.modal-camera video{
    max-width: 100%;
}

.modal-camera .modal-body{
    min-height: 520px;
}
.header-accountancy{
    height: 35px;
}


@media (max-width: 981px){
    .custom-sidebar-fixed{
        position: fixed;
        top: 0px;
    }
    
}
@media (min-width: 981px){
    .w-lg-40{
        width: 40%;
    }
}

