body{
    background-color: white !important;
    color: black !important;
    font-size: 1rem !important;
    font-family: "Arial";
    overflow-y: auto;
}

.content {
    padding: 0;
}

.content-title{
    color: #001452;
}

.content-title-bold{
    color: #001452;
    font-weight: 600;
}

.money-title{
    color: red
}

/* veso */
.day-so{
    font-family: Consolas;
    font-size: 1rem;
    width: 100%;
    /*margin-bottom: 5px;*/
    word-wrap: break-word;
    white-space: pre-wrap;
}

.day-so .chu-so{
    border-radius: 50% !important;
    border: solid 2px red;
    padding: 5px;
    display: inline-block;
    min-width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: normal;
    font-weight: bold;
}

.day-so .chu{
    border-radius: 25% !important;
    border: solid 2px red;
    padding: 5px;
    display: inline-block;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: normal;
    font-weight: bold;
}

.w-10{
    width: 10%;
}
.w-15{
    width: 15%;
}
.w-18{
    width: 18%;
}
.w-20{
    width: 20%;
}

.w-33{
    width: 33.33%;
}
.w-40{
    width: 40%;
}

.h-80{
    height: 80%;
}

/* opacity */
.opacity-20{
    opacity: 0.2;
}
.opacity-50{
    opacity: 0.5;
}

/* modal */
.modal-big{
    max-width: 1000px;
    max-height: 700px;
}

/* boder */
.borderRadius{
    border: solid 2px lightgrey;
    border-radius:10px !important;
}
